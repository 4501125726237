import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import router from '@/router'
import useDepartements from '@/services/admin/departementService'
import useAlertNotification from '@/services/notification/useAlert'

export default function useVilles() {
  const { errorToast, successToast } = useAlertNotification()
  const {
    getDepartement, departement,
  } = useDepartements()

  const ville = ref([])
  const loader = ref(false)
  const villeProcess = ref(false)
  const villeSuccess = ref(false)
  const villes = ref([])
  const errors = ref('')

  // Liste des villes
  const getVilles = async () => {
    loader.value = true
    await axiosClient.get('/villes').then(response => {
      if (response.data.success === true) {
        loader.value = false
        villes.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }
  // Obtenir les villes par pays
  const getVilleByDepartement = async () => {
    await getDepartement(router.currentRoute.params.slug)
    villes.value = departement.value.villes.length > 0 ? departement.value.villes : []
  }

  // Obtenir une ville
  const getVille = async slug => {
    const response = await axiosClient.get(`/villes/${slug}`)
    ville.value = response.data.data
  }
  const getVilleById = async id => {
    const response = await axiosClient.get(`/get-ville-by-id/${id}`)
    ville.value = response.data.data
  }
  // Ajouter une ville
  const createVille = async data => {
    errors.value = ''
    villeProcess.value = true
    await axiosClient.post('/villes', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          villeSuccess.value = true
          villeProcess.value = false
          getVilleByDepartement()
        }
      })
      .catch(error => {
        villeProcess.value = false
        villeSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  // Modifier une ville
  const updateVille = async data => {
    errors.value = ''
    villeProcess.value = true
    await axiosClient.patch(`/villes/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          villeSuccess.value = true
          villeProcess.value = false
          getVilleByDepartement()
        }
      })
      .catch(error => {
        villeProcess.value = false
        villeSuccess.value = false

        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  return {
    errors,
    ville,
    villes,
    getVilles,
    villeProcess,
    createVille,
    updateVille,
    getVille,
    departement,
    getVilleByDepartement,
    loader,
    villeSuccess,
    getVilleById,
  }
}
